import { alpha } from '@mui/material/styles';
import g2Theme from './g2Theme';

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}
const F = { main: g2Theme.genderColor['Femenino'] };
const M = { main: g2Theme.genderColor['Masculino'] };

const PRIMARY = {
    lighter: '#ECF8D4',
    light: '#D1E3A6',
    main: '#94b43b',
    dark: '#648500',
    darker: '#355800',
};
const SECONDARY = {
    lighter: '#ECF8D4',
    light: '#D1E3A6',
    main: '#648500',
    dark: '#355800',
    darker: '#355800',
};
const GREEN = {
    lighter: '#B7D175',
    light: '#ADC569',
    main: '#91B23B',
    dark: '#7C9933',
    darker: '#637A29',
    mainTranslucent: alpha('#91B23B', 0.7)
};
const CYAN = {
    lighter: '#75CBD1',
    light: '#57BFC7',
    main: '#3BABB2',
    dark: '#339299',
    darker: '#29757A',
    mainTranslucent: alpha('#3BABB2', 0.7)
};
const LIGHTER_BLUE = {
    lighter: '#6FACD8',
    light: '#4F99CF',
    main: '#3586C1',
    dark: '#2C70A0',
    darker: '#235980',
};
const DARKER_BLUE = {
    lighter: '#36A4D3',
    light: '#288DB8',
    main: '#15223B',
    dark: '#1A5A75',
    darker: '#124054',
};
const GRAY = {
    lighter: '#ABBBC4',
    light: '#93A8B4',
    main: '#748F9E',
    dark: '#648190',
    darker: '#546C78',
    mainTranslucent: alpha('#748F9E', 0.7)
};
const SALMON = {
    lighter: '#EAAEAE',
    light: '#E28D8D',
    main: '#D86666',
    dark: '#D14D4D',
    darker: '#C33232',
};
const BURGUNDY = {
    lighter: '#DD4058',
    light: '#D0253E',
    main: '#A31D31',
    dark: '#8B182A',
    darker: '#68121F',
    mainTranslucent: alpha('#A31D31', 0.8)
};
const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#182F58',
};
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
};
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
};
const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
};
const DARK = {
    lighter: '#C4CDD5',
    light: '#919EAB',
    main: '#637381',
    dark: '#454F5B',
    darker: '#212B36',
};
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};
const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    secondary: createGradient(SECONDARY.light, SECONDARY.main),
    green: createGradient(GREEN.light, GREEN.main),
    cyan: createGradient(CYAN.light, CYAN.main),
    lighterBlue: createGradient(LIGHTER_BLUE.light, LIGHTER_BLUE.main),
    darkerBlue: createGradient(DARKER_BLUE.light, DARKER_BLUE.main),
    gray: createGradient(GRAY.light, GRAY.main),
    salmon: createGradient(SALMON.light, SALMON.main),
    burgundy: createGradient(BURGUNDY.light, BURGUNDY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};
const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};
const COMMON = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY, contrastText: '#fff' },
    secondary: { ...SECONDARY, contrastText: '#fff' },
    green: { ...GREEN, contrastText: '#fff' },
    cyan: { ...CYAN, contrastText: '#fff' },
    lighterBlue: { ...LIGHTER_BLUE, contrastText: '#fff' },
    darkerBlue: { ...DARKER_BLUE, contrastText: '#fff' },
    gray: { ...GRAY, contrastText: '#fff' },
    salmon: { ...SALMON, contrastText: '#fff' },
    burgundy: { ...BURGUNDY, contrastText: '#fff' },
    info: { ...INFO, contrastText: '#fff' },
    success: { ...SUCCESS, contrastText: '#fff' },
    warning: { ...WARNING, contrastText: '#fff' },
    error: { ...ERROR, contrastText: '#fff' },
    dark: { ...DARK, contrastText: '#fff' },
    female: { ...F, contrastText: '#fff' },
    male: { ...M, contrastText: '#fff' },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    action: {
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};
const palette = {
    light: {
        ...COMMON,
        mode: 'light',
        text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
        background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
        action: { active: GREY[600], ...COMMON.action },
    },
    dark: {
        ...COMMON,
        mode: 'dark',
        text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
        background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
        action: { active: GREY[500], ...COMMON.action },
    }
};
export const COLORS_GROUP = {
    'A1': g2Theme.colors10[0],
    'A': g2Theme.colors10[1],
    'B': g2Theme.colors10[2],
    'C': g2Theme.colors10[3],
    'D': g2Theme.colors10[4],
    'S': g2Theme.colors10[5],
    'R': g2Theme.colors10[6],
    'G': g2Theme.colors10[7],
    'Sin Categoría': g2Theme.colors10[8],
};
export const COLORS_STATE = {
    'Activo': PRIMARY['main'],
    'Retirado': ERROR['main'],
    'No': GREY['main']
}
export const COLORS_USABILITY = {
    'init': g2Theme.colors10[0],
    'dependence': g2Theme.colors10[1],
    'hierarchy': g2Theme.colors10[2],
    'resource': g2Theme.colors10[3],
    'subResource': g2Theme.colors10[4],
};
export default palette;