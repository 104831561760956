import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Box } from '@mui/material';
import { FilterAltTwoTone } from '@mui/icons-material';
import Tooltip from './Tooltip';
import { formatDependenciesName, getParamsRouteString } from '../../utils/utils';

function CustomBreadcrumbs(props) {
    const { hierarchy, params, dependence, settings, setMetricsDefault, setLoginDefault } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    let paramsString = '';

    const handleRoute = (itemParams) => () => {
        let route = '';
        setMetricsDefault();
        setLoginDefault();
        if ('resource' in params) {
            route = [itemParams, 'resource', getParamsRouteString(params.resource)].join('/');
        } else {
            route = itemParams;
        }
        navigate(route, { state: { key: 'hierarchy' } });
    };

    const currentDate = `${t('components.breadcrumbs.data')}: ${new Date(dependence[0]?.date).toLocaleDateString(settings.language === 'en' ? 'en-US' : 'es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })}`;

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Breadcrumbs color='inherit'>
                {hierarchy && (
                    <Tooltip title={t('components.breadcrumbs.tooltip')} placement='bottom'>
                        <Typography
                            variant='subtitle2'
                            fontSize='inherit'
                        >
                            <FilterAltTwoTone sx={{ mr: 0.5 }} fontSize='small' />
                            {t('components.breadcrumbs.' + hierarchy) + ': '}
                        </Typography>
                    </Tooltip>
                )}
                {Object.keys(params).filter(item => item !== 'resource').map((item, key) => {
                    paramsString = paramsString + '/' + item + '/' + getParamsRouteString(params[item]);
                    return (
                        <Tooltip
                            key={key}
                            title={t('components.breadcrumbs.filter') + '\'' + formatDependenciesName(params[item]) + '\''}
                            placement='bottom'
                        >
                            <Typography
                                variant='caption'
                                fontSize='inherit'
                                sx={{
                                    '&:hover': { textDecoration: 'underline' },
                                    cursor: 'pointer'
                                }}
                                onClick={(Object.keys(params).filter(item => item !== 'resource').length - 1) === key ? null : handleRoute(paramsString)}
                            >
                                {formatDependenciesName(params[item])}
                            </Typography>
                        </Tooltip>
                    )
                })}
            </Breadcrumbs>
            <Typography variant='caption' fontSize='inherit' sx={{ ml: 2 }}>
                {currentDate}
            </Typography>
        </Box>
    );
};
CustomBreadcrumbs.propTypes = {
    hierarchy: PropTypes.string,
    params: PropTypes.object
};
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT'
        });
    },
    setLoginDefault() {
        dispatch({
            type: 'SET_LOGIN_DEFAULT'
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomBreadcrumbs);