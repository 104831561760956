import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Grid } from '@mui/material';
// LAYOUT
import Home from '../mui/views/layout/Home';
import Header from '../mui/views/layout/Header';
import Footer from '../mui/views/layout/Footer';
// INVESTIGATION
import SearchInv from '../mui/views/dependence/investigation/Search';
import Institution from '../mui/views/dependence/investigation/Institution';
import Campus from '../mui/views/dependence/investigation/Campus';
import Faculty from '../mui/views/dependence/investigation/Faculty';
import Uab from '../mui/views/dependence/investigation/Uab';
import Author from '../mui/views/dependence/investigation/Author';
import Group from '../mui/views/dependence/investigation/Group';
import Seedbed from '../mui/views/dependence/investigation/Seedbed';
// EXTENSION
import SearchExt from '../mui/views/dependence/extension/Search';
import Extension from '../mui/views/dependence/extension/Extension';
// REPORTS
import Reports from '../mui/views/dependence/reports/Reports';
// AUTHENTICATION
import Login from '../mui/views/dependence/login/Login';
import Panel from '../mui/views/dependence/login/admin/Panel';
// AUTHENTICATION - EXTENSION
import Upload from '../mui/views/dependence/login/extension/Upload';
// AUTHENTICATION - CURATOR
import Curator from '../mui/views/dependence/login/curator/Curator';
import Publication from '../mui/views/dependence/login/curator/Publication';
const routes = [
    // HOME
    { path: '/', name: 'Inicio', Component: Home },
    // INVESTIGATION
    { path: '/institution/:institution/resource/:resource', name: 'Institución', Component: Institution },
    { path: '/institution/:institution/campus/:campus/resource/:resource', name: 'Sede', Component: Campus },
    { path: '/institution/:institution/campus/:campus/faculty/:faculty/resource/:resource', name: 'Facultad', Component: Faculty },
    { path: '/institution/:institution/campus/:campus/faculty/:faculty/uab/:uab/resource/:resource', name: 'Uab', Component: Uab },
    { path: '/institution/:institution/campus/:campus/faculty/:faculty/uab/:uab/author/:author/resource/:resource', name: 'Profesor', Component: Author },
    { path: '/institution/:institution/campus/:campus/faculty/:faculty/uab/:uab/author/:author/group/:group/resource/:resource', name: 'Grupo', Component: Group },
    { path: '/institution/:institution/campus/:campus/faculty/:faculty/uab/:uab/author/:author/seedbed/:seedbed/resource/:resource', name: 'Semillero', Component: Seedbed },
    { path: '/search/research/query/:query/resource/:resource', name: 'Buscador investigación', Component: SearchInv },
    // EXTENSION
    { path: '/extension/:extension/resource/:resource', name: 'Extensión', Component: Extension },
    { path: '/search/extension/query/:query/resource/:resource', name: 'Buscador extension', Component: SearchExt },
    // REPORTS
    { path: '/reports/:reports', name: 'Reportes', Component: Reports },
    // AUTHENTICATION
    { path: '/login/:login', name: 'Inicio de sesión', Component: Login },
    { path: '/login/:login/panel/:panel', name: 'Panel de control', Component: Panel },
    // AUTHENTICATION - EXTENSION
    { path: '/login/:login/upload/:upload', name: 'Cargue de archivos extensión', Component: Upload },
    // AUTHENTICATION - CURATOR
    { path: '/login/:login/curator/:curator', name: 'Curaduria', Component: Curator },
    { path: '/login/:login/curator/:curator/add/:add', name: 'Nueva Publicación', Component: Publication },
    { path: '/login/:login/curator/:curator/publication/:publication', name: 'Publicación activa', Component: Publication },
    { path: '/login/:login/curator/:curator/deleted/:deleted/publication/:publication', name: 'Publicación eliminada', Component: Publication },
];
function Router(props) {
    const { setSettings } = props;
    const [height, setHeight] = useState({
        heightTop: 0,
        heightBottom: 0
    });

    const fontAwesomeCSS = document.createElement('link');
    fontAwesomeCSS.rel = 'stylesheet';
    fontAwesomeCSS.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
    document.head.appendChild(fontAwesomeCSS);

    useEffect(() => {
        let heightTop = document.getElementById('unalTop').clientHeight;
        let heightBottom = document.getElementById('unalBottom').clientHeight;
        setHeight({ heightTop: heightTop, heightBottom: heightBottom });
        setSettings({ heightTop: heightTop, heightBottom: heightBottom });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BrowserRouter>
            <Header />
            <Grid
                id='main-horus'
                alignItems='center'
                justifyContent='center'
                sx={{
                    mt: `${height.heightTop}px`,
                    minHeight: window.screen.height - height.heightTop - height.heightBottom,
                    height: '100%', alignItems: 'stretch'
                }}
            >
                <Routes>
                    {routes.map(({ path, Component }, key) => (
                        <Route
                            key={key}
                            path={path}
                            element={<Component />}
                        />
                    ))}
                    <Route path='/*' element={<Home />} />
                </Routes>
            </Grid>
            <Footer />
        </BrowserRouter>
    );
}
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Router);