import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { constants } from '../../../../../constants';
import { getParams, getParamsRouteString } from '../../../../../utils/utils';

function GroupSeo(props) {
    const { data, dependence } = props;

    const group = data[constants.METRIC_GROUPS_INFO_FIELD].processed;

    const name = group[constants.GROUP_NAME_FIELD];
    const email = group[constants.GROUP_EMAIL_FIELD];
    const state = group[constants.GROUP_STATE_FIELD];
    const descriptionGroup = group[constants.GROUP_DESCRIPTION_FIELD];
    const urlHermes = group[constants.GROUP_URL_FIELD];
    const urlGroupLac = group[constants.GROUP_URL_GROUPLAC_FIELD];
    const ocde = group[constants.GROUP_OCDE_FIELD];
    const agenda = group[constants.GROUP_AGENDA_FIELD];
    const lines = group[constants.GROUP_LINES_FIELD];
    const col_code = group[constants.GROUP_COL_CODE_FIELD];
    const col_category = group[constants.GROUP_COL_CATEGORY_FIELD];
    const col_state = group[constants.GROUP_COL_STATE_FIELD];

    const title = `${name} - HORUS Visibilidad Científica y Tecnológica`;
    const description = `Perfil de grupo de investigación ${state}. ${descriptionGroup}`;
    const keywords = [name, email, ocde, agenda, col_code, col_category, col_state].concat(lines).join(', ');
    const url = ['https://horus.unal.edu.co' + getParams(dependence, group[constants.GROUP_LEADER_FIELD]), 'group', getParamsRouteString(name), 'resource', 'calls'].join('/');

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'Person',
        'name': name,
        'email': email,
        'sameAs': Object.values({ ...urlHermes, ...urlGroupLac }).flat().filter(url => url),
        'url': url
    };
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta name='group' content={name} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='profile' />
            {urlHermes.length > 0 && <meta property='og:url' content={urlHermes[0]} />}
            {urlGroupLac.length > 0 && <meta property='og:url' content={urlGroupLac[0]} />}
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <link rel='canonical' href={url} />
            <meta property='profile:username' content={name} />
            <script type='application/ld+json'>{JSON.stringify(jsonLd)}</script>
        </Helmet>
    );
};
const mapStateToProps = (state) => ({
    dependence: state.dependence
});
export default connect(mapStateToProps)(GroupSeo);