import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, Grid, Autocomplete, IconButton, Avatar } from '@mui/material';
import { faUserShield, faUser, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import CardEmpty from '../card/CardEmpty';
import { ForceGraph2D } from 'react-force-graph';
import { configGraph2D, configColor } from '../../../assets/theme/g2Theme';
import { hexToRgba } from '../../../utils/utils';

function Graph2D(props) {
    const { infoChart } = props;

    const ref = useRef(null);
    const { t } = useTranslation();

    let clusterId = 0;
    let clusterLegend = new Map();

    const optionsType = [
        { label: t('components.graphics.graph2D.labelRandom'), value: null },
        { label: t('components.graphics.graph2D.labelRadial'), value: 'radialout' }
    ];

    const nodeIcons = {
        '01. Autor perfil': ['\uf505', faUserShield, true],
        '02. Autores universidad': ['\uf007', faUser, true],
        '03. Autores externos': ['\uf007', faUserRegular, false],
        '02. Temas de investigación': ['\uf1c2', faFileWord, true]
    };

    const [values, setValues] = useState({
        graph: { nodes: [], links: [] },
        type: optionsType[0],
        highlightNode: null,
        highlightNodes: new Set(),
        highlightLinks: new Set(),
        clusterMap: new Map(),
        fullScreen: false
    });

    const handleType = (event, value) => {
        setValues(prevState => ({ ...prevState, type: value }));
    };

    const handleHighlight = (node) => {
        setValues(prevState => ({
            ...prevState,
            highlightNode: node,
            highlightNodes: new Set([node, ...node.nodes]),
            highlightLinks: new Set(node.links)
        }));
        ref.current.centerAt(node.x, node.y, 1000);
        ref.current.zoom(2, 1000);
    };

    const handleFullScreen = () => {
        const graphContainer = document.getElementById('overflow-container');
        if (graphContainer && !values.fullScreen) {
            setValues(prevState => ({ ...prevState, fullScreen: true }));
            graphContainer.style.position = 'fixed';
            graphContainer.style.top = '0';
            graphContainer.style.left = '0';
            graphContainer.style.width = '100%';
            graphContainer.style.height = '100%';
            graphContainer.style.zIndex = '1100';
        } else {
            setValues(prevState => ({ ...prevState, fullScreen: false }));
            graphContainer.style.position = '';
            graphContainer.style.top = '';
            graphContainer.style.left = '';
            graphContainer.style.width = '';
            graphContainer.style.height = '';
            graphContainer.style.zIndex = '';
        }
    };

    const handleLabel = (text) => {
        return (
            `
                <div style="
                    background-color: #F4F4F4;
                    padding: 5px 10px;
                    border-radius: 4px;
                    color: #2f2f2f;
                    font-family: 'Ancizar sans', sans-serif;
                    font-size: 14px;
                    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
                    margin: -5px;
                ">
                    ${text}
                </div>
            `
        )
    };

    const onNodeDragEnd = (node) => {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
    };

    const nodeCanvasObject = useCallback((node, ctx) => {
        const { id, group, cluster } = node;
        let setActive = false;
        const fontSize = 3;
        clusterLegend = values.clusterMap;
        const fieldCluster = cluster ? 'cluster' : 'group';
        const valueCluster = node[fieldCluster];
        if (clusterLegend.get(valueCluster) === undefined) {
            let icon = nodeIcons[group];
            if (!icon) {
                if (group.includes('Autores')) {
                    icon = nodeIcons['02. Autores universidad']
                } else if (group.includes('Temas de investigación')) {
                    icon = nodeIcons['02. Temas de investigación']
                }
            }
            clusterLegend.set(valueCluster, {
                color: configColor[clusterId % configColor.length],
                icon: icon,
                label: group
            });
            clusterId++;
            setActive = true;
        }
        const legend = values.clusterMap.get(valueCluster);
        const size = values.highlightNodes.size === 0;
        const hasNode = values.highlightNodes.has(node);
        const radius = size ? 4 : (hasNode ? 4.5 : 3);
        const colorHex = hexToRgba(legend?.color, size ? 1 : (hasNode ? 1 : 0.05));
        if (hasNode) {
            ctx.beginPath();
            ctx.arc(node.x, node.y, radius + 1.25, 0, 2 * Math.PI, false);
            ctx.fillStyle = node === values.highlightNode ? 'red' : 'orange';
            ctx.fill();
        }
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = colorHex;
        ctx.fill();
        ctx.fillStyle = '#FFF';
        ctx.font = `${legend?.icon[2] ? '900' : '400'} ${radius * 0.8}px "Font Awesome 5 Free"`;
        ctx.textBaseline = 'middle';
        ctx.fillText(legend?.icon?.[0], node.x - 1.25, node.y);
        const bckgDimensions = [ctx.measureText(id).width, fontSize].map(n => n + fontSize * 0.2);
        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillRect(node.x + (hasNode ? 6 : 4), node.y - bckgDimensions[1] / 2, bckgDimensions[0] * 0.61, bckgDimensions[1]);
        ctx.fillStyle = '#000000';
        ctx.font = `${fontSize}px Ancizar sans`;
        ctx.textBaseline = 'middle';
        ctx.fillText(id, node.x + (hasNode ? 6.2 : 4.2), node.y);
        node.__bckgDimensions = bckgDimensions;
        if (setActive) {
            setValues(prevState => ({ ...prevState, clusterMap: clusterLegend }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.highlightNode]);

    const linkCanvasObject = useCallback((link, ctx, globalScale) => {
        const { source, target, value } = link;
        const fieldCluster = source?.cluster ? 'cluster' : 'group';
        const valueCluster = source[fieldCluster];
        const legend = values.clusterMap.get(valueCluster);
        const colorHex = hexToRgba(legend?.color, values.highlightNodes.size === 0 ? 0.3 : (values.highlightLinks.has(link) ? 1 : 0.05));
        const lineWidth = value / (globalScale * 1.75);
        ctx.beginPath();
        ctx.moveTo(source.x, source.y);
        ctx.lineTo(target.x, target.y);
        ctx.strokeStyle = colorHex;
        ctx.lineWidth = lineWidth;
        ctx.stroke();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.highlightNode]);

    const updateHighlight = () => {
        setValues(prevState => ({
            ...prevState,
            highlightNodes: values.highlightNodes,
            highlightLinks: values.highlightLinks
        }));
    };

    const handleNode = (node) => {
        values.highlightNodes.clear();
        values.highlightLinks.clear();
        if (node) {
            values.highlightNodes.add(node);
            node.nodes.forEach(neighbor => values.highlightNodes.add(neighbor));
            node.links.forEach(link => values.highlightLinks.add(link));
            setValues(prevState => ({ ...prevState, highlightNode: node }));
        }
        updateHighlight();
    };

    const handleLink = (link) => {
        values.highlightNodes.clear();
        values.highlightLinks.clear();
        if (link) {
            values.highlightLinks.add(link);
            values.highlightNodes.add(link.source);
            values.highlightNodes.add(link.target);
        }
        updateHighlight();
    };

    const handleReset = () => {
        setValues(prevState => ({
            ...prevState,
            highlightNode: null,
            highlightNodes: new Set(),
            highlightLinks: new Set()
        }));
    };

    useEffect(() => {
        if (infoChart.data.nodes.length > 0) {
            let nodes = [...infoChart.data.nodes].reduce((acc, item) => ({ ...acc, [item.id]: { ...item, nodes: [], links: [] } }), {});
            let links = [...infoChart.data.edges];
            links.forEach(link => {
                const a = nodes[link.source];
                const b = nodes[link.target];
                if (a) {
                    a.nodes.push(b);
                    a.links.push(link);
                }
                if (b) {
                    b.nodes.push(a);
                    b.links.push(link);
                }
            });
            setValues(prevState => ({
                ...prevState,
                graph: { nodes: Object.values(nodes), links: links }
            }));
        }
    }, [infoChart.data]);

    return (
        <Box id='overflow-container' sx={{ bgcolor: 'white', p: 2 }}>
            <Grid container spacing={3} sx={{ mb: 1 }}>
                <Grid item xs={12} md={5.5}>
                    <Tooltip title={t('components.graphics.graph2D.typeTooltip')}>
                        <Autocomplete
                            disableClearable
                            onChange={handleType}
                            value={values.type}
                            options={optionsType}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph2D.type')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={9} md={5.5}>
                    <Tooltip title={t('components.graphics.graph2D.highlightTooltip')}>
                        <Autocomplete
                            disableClearable
                            onChange={(event, value) => handleHighlight(value)}
                            value={values.highlightNode}
                            options={values.graph.nodes.sort((a, b) => a.group.localeCompare(b.group))}
                            groupBy={(option) => option.group}
                            getOptionLabel={(option) => option.id}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph2D.highlight')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={3} md={1}>
                    <Tooltip title={t('components.graphics.graph2D.screenAll')}>
                        <IconButton onClick={handleFullScreen} >
                            <Avatar
                                sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: theme => theme.palette.primary.main,
                                    fontSize: 16,
                                }}
                            >
                                {values.fullScreen ? <FullscreenExit /> : <Fullscreen />}
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Grid>
                {
                    Array.from(values.clusterMap).map((item, index) => {
                        const data = item[1];
                        return (
                            <Grid key={index} item xs={12} md={3} style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                                <Avatar sx={{ bgcolor: data.color, width: 30, height: 30, mr: 1 }}>
                                    <FontAwesomeIcon icon={data.icon?.[1]} size='sm' />
                                </Avatar>
                                {data.label.substring(3)}
                            </Grid>
                        )
                    })
                }
            </Grid>
            {infoChart.data.nodes.length === 0 ? (
                <CardEmpty />
            ) : (
                <ForceGraph2D
                    ref={ref}
                    width={values.fullScreen ? window.innerWidth - 50 : window.innerWidth - 180}
                    height={values.fullScreen ? window.innerHeight - 100 : 500}
                    backgroundColor={configGraph2D.backgroundColor}
                    dagLevelDistance={50}
                    dagMode={values.type.value}
                    graphData={{ ...values.graph }}
                    linkLabel={link => handleLabel(`${link.source.id} - ${link.target.id}: ${link.value}`)}
                    onNodeDragEnd={onNodeDragEnd}
                    nodeCanvasObject={nodeCanvasObject}
                    linkCanvasObject={linkCanvasObject}
                    autoPauseRedraw={false}
                    nodeCanvasObjectMode={node => values.highlightNodes.has(node) ? 'after' : 'replace'}
                    onNodeClick={handleNode}
                    onLinkClick={handleLink}
                    onBackgroundClick={handleReset}
                />
            )}
        </Box>
    );
}
Graph2D.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.object,
        legend: PropTypes.element
    })
};
export default Graph2D;