import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CardProductsList from '../../../../../components/card/CardProductsList';
import { formatMessage, filterDependenciesInv } from '../../../../../../utils/utils';
import { requestPost } from '../../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../../constants';

function Publications(props) {
    const { author, order, dependence, investigation, login, setInvestigation, setLogin, setSettings } = props;

    const rowsPage = 100;

    const navigate = useNavigate();
    const hierarchy = {
        ...investigation.hierarchy,
        resource: 'Lista de Productos'
    };

    const handleFilter = (filter, record, response) => {
        if (record?.action?.type === 'Visualizar publicación en enlace externo (URL)') {
            requestPost(`${URL_API_INV}/usability/resource`, { record: record });
        } else {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de productos') });
            return Promise.all([
                requestPost(`${URL_API_INV}/curator/products`, {
                    filter: filter,
                    record: record
                })
            ])
                .then(res => {
                    if (response === 'state') {
                        if (res[0].data.access && res[0].data.infoUser.dependence === 'Curaduria') {
                            if (response === 'state') {
                                if (record?.action?.type !== 'Visualizar publicación en enlace externo (URL)') {
                                    setLogin({
                                        admin: { infoUser: res[0].data.infoUser },
                                        curator: {
                                            publications: res[0].data.infoPublications[constants.METRIC_PUBLICATIONS_LIST_FIELD],
                                            total: res[0].data.infoPublications[constants.METRIC_METRICS_FIELD]
                                        }
                                    });
                                }
                            } else if (response === 'return') {
                                setSettings({ backdrop: formatMessage(false, '', '') });
                                return res[0].data[constants.METRIC_BOTH_FIELD][constants.METRIC_PUBLICATIONS_LIST_FIELD]
                            }
                        } else if (res[0].data.access) {
                            navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                        } else {
                            sessionStorage.removeItem('log-ide');
                            navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                        }
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    } else if (response === 'return') {
                        setSettings({ backdrop: formatMessage(false, '', '') });
                        return res[0].data[constants.METRIC_BOTH_FIELD][constants.METRIC_PUBLICATIONS_LIST_FIELD]
                    }
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                    throw err;
                });
        }
    };

    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            setSettings({ backdrop: formatMessage(true, '', 'Consultando información de productos.') });
            const metrics = filterDependenciesInv('institution', dependence, {institution: constants.DEPENDENCE_INSTITUTION_VALUE_FIELD}, investigation, null, null, null);
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/general_metrics`, { _id: metrics.metrics.general, record: 'none'}),
                requestPost(`${URL_API_INV}/curator/products`, {
                    filter: {
                        complete: false,
                        page: 0,
                        rowsPage: rowsPage,
                        contentSearch: '',
                        authorOption: [],
                        typeOption: [],
                        order: order
                    },
                    record: hierarchy
                })
            ])
                .then(res => {
                    if (res[1].data.access && res[1].data.infoUser.dependence === 'Curaduria') {
                        const metricsA = res[0].data[constants.METRIC_BOTH_FIELD];
                        const projects_authors = Object.keys(metricsA[constants.METRIC_PROJECTS_AUTHORS_FIELD]?.True || {}).map(item => parseInt(item, 10));
                        const products_authors = Object.keys(metricsA[constants.METRIC_PUBLICATIONS_AUTHORS_FIELD]?.True || {}).map(item => parseInt(item, 10));
                        setLogin({
                            admin: { infoUser: res[1].data.infoUser },
                            curator: {
                                author: [...projects_authors, ...products_authors],
                                type: [...metricsA[constants.METRIC_PUBLICATIONS_TYPE_FIELD] || [], ...metricsA[constants.METRIC_PROJECTS_TYPE_FIELD] || []],
                                publications: res[1].data.infoPublications[constants.METRIC_PUBLICATIONS_LIST_FIELD],
                                total: res[1].data.infoPublications[constants.METRIC_METRICS_FIELD]
                            }
                        });
                        setInvestigation({ hierarchy: hierarchy });
                    } else if (res[1].data.access) {
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    } else {
                        sessionStorage.removeItem('log-ide');
                        navigate('/login/Inicio de sesión', { state: { key: 'hierarchy' } });
                    }
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);

    return (
        <Box>
            <CardProductsList
                data={login.curator.publications}
                params={{ author: 'Curaduria' }}
                hierarchy={hierarchy}
                options={{
                    total: login.curator.total,
                    order: order,
                    rowsPage: rowsPage,
                    author: author,
                    curator: { deleted: false, edit: true },
                    metrics: {
                        author: login.curator.author,
                        type: login.curator.type
                    }
                }}
                handleFilter={handleFilter}
            />
        </Box>
    )
}
Publications.propTypes = {
    author: PropTypes.array,
    order: PropTypes.object
};
Publications.defaultProps = {
    author: [],
    order: {}
};
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    investigation: state.investigation,
    login: state.login
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setLogin(state) {
        dispatch({
            type: 'SET_LOGIN',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Publications);