import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Box, Card, Tooltip, IconButton, Avatar, Typography, Chip, Grid } from '@mui/material';
import { CalendarMonthTwoTone, Person3TwoTone, PersonOutlineTwoTone, MarkEmailReadTwoTone, PersonSearchTwoTone } from '@mui/icons-material';
import { formatDate, formatDependenciesName, getLeader } from '../../../utils/utils';
import { constants } from '../../../constants';

function CardGroup(props) {
    const { group } = props;

    const { t } = useTranslation();
    const leader = getLeader(group[constants.GROUP_MEMBERS_FIELD]);

    const information = [
        {
            key: group[constants.GROUP_EMAIL_FIELD],
            label: t('components.card.cardGroup.email'),
            icon: <MarkEmailReadTwoTone />
        },
        {
            key: formatDate(group[constants.GROUP_DATE_FIELD]),
            label: t('components.card.cardGroup.date'),
            icon: <CalendarMonthTwoTone />
        },
        {
            key: group[constants.GROUP_STATE_FIELD],
            label: t('components.card.cardGroup.state'),
            icon: <PersonSearchTwoTone />
        }
    ];

    return (
        <Card
            elevation={4}
            sx={{
                borderRadius: 1,
                bgcolor: 'rgba(218, 218, 218, 0.28)',
                backdropFilter: 'blur(6px)',
                color: 'white',
                textShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
                pl: 4,pr:3, pt:3, pb:3,
                width: '100%',
                height:'100%'
            }}
        >
            <Grid
                container
                direction={{ xs: 'column', sm: 'row' }}
                spacing={1}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    alignContent={'center'}
                >
                    <Typography variant='h5'
                        sx={{
                            lineHeight: 1,
                            marginBottom: '4px'
                        }}
                    >
                        {group[constants.GROUP_NAME_FIELD]}
                    </Typography>
                    {information.map((info, key) => (
                        <Tooltip
                            key={key}
                            title={info.label + t('components.card.cardGroup.informationTooltip')}
                        >
                            <Stack
                                direction={'row'}
                                spacing={1}
                                alignContent={'center'}
                            >
                                {info.icon}
                                <Typography variant='body1'
                                    sx={{
                                        lineHeight: 1,
                                        wordBreak: 'break-word',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    {info.key}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    ))}
                    <Typography variant='body1'
                        sx={{
                            display: 'block',
                            lineHeight: 1,
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderLeft: '3px solid',
                            textAlign: 'justify',
                            marginBottom: 1,
                            mt:1,
                        }}
                    >
                        {group[constants.GROUP_DESCRIPTION_FIELD]}
                    </Typography>
                    <Typography variant='h5'>
                        {t('components.card.cardGroup.leader')}
                    </Typography>
                    <Stack
                        direction='row'
                        spacing={1.5}
                        alignItems='center'
                    >
                        <Avatar
                            sx={{
                                width: 60,
                                height: 60,
                                bgcolor: theme => theme.palette[leader[constants.AUTHOR_GENDER_FIELD] === 'F' ? 'female' : 'male'].main
                            }}
                        >
                            {leader[constants.AUTHOR_IMAGE_FIELD] ? (
                                <Box
                                    component='img'
                                    width='100%'
                                    src={
                                        leader[constants.AUTHOR_IMAGE_FIELD].includes('http')
                                            ? leader[constants.AUTHOR_IMAGE_FIELD]
                                            : require('../../../assets/images/' + leader[constants.AUTHOR_IMAGE_FIELD])
                                    }
                                />
                            ) : (
                                <Box
                                    component={
                                        leader[constants.AUTHOR_GENDER_FIELD] === 'F'
                                            ? Person3TwoTone
                                            : PersonOutlineTwoTone
                                    }
                                    width={40}
                                    height={40}
                                />
                            )}
                        </Avatar>
                        <Stack>
                            <Typography variant='body1' sx={{ fontWeight: 600, letterSpacing: 0.3}}>
                                {leader[constants.AUTHOR_NAME_FIELD]}
                            </Typography>
                            <Typography variant='body2' >
                                {formatDependenciesName(leader[constants.AUTHOR_UAB_FIELD])}
                            </Typography>
                            <Typography variant='body2' sx={{ lineHeight: 0.8}}>
                                {formatDependenciesName(leader[constants.AUTHOR_FACULTY_FIELD])}
                            </Typography>
                            <Typography variant='body2'>
                                {formatDependenciesName(leader[constants.AUTHOR_CAMPUS_FIELD])}
                            </Typography>
                        </Stack>
                    </Stack>
                    
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 'auto',
                        maxWidth: '100%',
                    }}
                >
                    <Box sx={{ flexGrow: 1 , mt:5}}>
                        <Typography variant='h5'>{t('components.card.cardGroup.colciencias')}</Typography>
                        <Typography variant='body1' mb='-5px'>
                            <b>- {t('components.card.cardGroup.colcienciasCode')}:</b> {group[constants.GROUP_COL_CODE_FIELD]}
                        </Typography>
                        <Typography variant='body1' mb='-5px'>
                            <b>- {t('components.card.cardGroup.colcienciasCategory')}:</b> {group[constants.GROUP_COL_CATEGORY_FIELD]}
                        </Typography>
                        <Typography variant='body1'>
                            <b>- {t('components.card.cardGroup.colcienciasState')}:</b> {group[constants.GROUP_COL_STATE_FIELD]}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            textAlign: 'left',
                        }}>
                        <Typography variant='h5'>
                            {t('components.card.cardGroup.lines')}
                        </Typography>
                        <Box
                            sx={{
                                marginBottom: 0.5, 
                            }}
                        >
                            {group[constants.GROUP_LINES_FIELD].map((line, key) =>
                                <Chip
                                    key={line}
                                    label={
                                        <Typography variant='body2' sx={{
                                            p: 0.8,
                                            whiteSpace: 'normal', 
                                            overflow: 'visible',
                                            textOverflow: 'clip',
                                            display: 'block', 
                                            wordBreak: 'break-word', 
                                            lineHeight:1,
                                        }}>
                                            {line}
                                        </Typography>
                                    }
                                    sx={{
                                        mb: 0.4,
                                        mr: 0.4,
                                        color: 'white',
                                        bgcolor: 'rgba(0, 0, 0, 0.25)',
                                        height:'auto',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', mb:{xs:0, sm:4}}}>
                        <Typography variant='h5' mt='8px'>
                            {t('components.card.cardGroup.profiles')}
                        </Typography>
                        <Stack direction='row' spacing={1}>
                            {group[constants.GROUP_URL_FIELD] && (
                                <Tooltip title={t('components.card.cardGroup.linkHermes')}>
                                    <IconButton href={group[constants.GROUP_URL_FIELD]} target='_blank' rel='noreferrer' sx={{ p: 0 }}>
                                        <Avatar
                                            variant='rounded'
                                            src={require('../../../assets/images/horus/hermes.png')}
                                            sx={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {group[constants.GROUP_URL_GROUPLAC_FIELD] && (
                                <Tooltip title={t('components.card.cardGroup.linkGrupLac')}>
                                    <IconButton href={group[constants.GROUP_URL_GROUPLAC_FIELD]} target='_blank' rel='noreferrer' sx={{ p: 0}}>
                                        <Avatar
                                            variant='rounded'
                                            src={require('../../../assets/images/horus/gruplac.png')}
                                            sx={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Box>
                    
                </Grid>
            </Grid>
        </Card>
    );
};
CardGroup.propTypes = {
    group: PropTypes.object,
};
CardGroup.defaultProps = {
    group: {},
};
export default CardGroup;