import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { constants } from '../../../../../constants';
import { getParams, getParamsRouteString } from '../../../../../utils/utils';

function SeedbedSeo(props) {
    const { data, dependence } = props;

    const seedbed = data[constants.METRIC_SEEDBEDS_INFO_FIELD].processed;

    const name = seedbed[constants.SEEDBEDS_NAME_FIELD];
    const email = seedbed[constants.SEEDBEDS_EMAIL_FIELD];
    const state = seedbed[constants.SEEDBEDS_STATE_FIELD];
    const urlHermes = seedbed[constants.SEEDBEDS_URL_FIELD];
    const objetive = seedbed[constants.SEEDBEDS_OBJETIVE_FIELD];
    const justification = seedbed[constants.SEEDBEDS_JUSTIFICATION_FIELD];
    const ocde = seedbed[constants.SEEDBEDS_OCDE_FIELD];
    const ods = seedbed[constants.SEEDBEDS_ODS_FIELD];
    const lines = seedbed[constants.SEEDBEDS_LINES_FIELD];

    const title = `${name} - HORUS Visibilidad Científica y Tecnológica`;
    const description = `Perfil de semillero de investigación ${state}. ${justification}. ${objetive}`;
    const keywords = [name, email, ocde, ods].concat(lines).join(', ');
    const url = ['https://horus.unal.edu.co' + getParams(dependence, seedbed[constants.SEEDBEDS_LEADER_FIELD]), 'seedbed', getParamsRouteString(name), 'resource', 'members'].join('/');

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'Person',
        'name': name,
        'email': email,
        'sameAs': Object.values({ ...urlHermes }).flat().filter(url => url),
        'url': url
    };
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta name='seedbed' content={name} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='profile' />
            {urlHermes.length > 0 && <meta property='og:url' content={urlHermes[0]} />}
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <link rel='canonical' href={url} />
            <meta property='profile:username' content={name} />
            <script type='application/ld+json'>{JSON.stringify(jsonLd)}</script>
        </Helmet>
    );
};
const mapStateToProps = (state) => ({
    dependence: state.dependence
});
export default connect(mapStateToProps)(SeedbedSeo);