import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Stack } from '@mui/material';
import Timeline from '../../../../components/timeline/Timeline';
import { formatTLGroups } from '../../../../../utils/utils';
import { requestPost } from '../../../../../utils/request';
import { constants, URL_API_INV } from '../../../../../constants';

function Calls(props) {
    const { investigation, settings, setInvestigation } = props;

    const group = investigation.metrics.general[constants.METRIC_GROUPS_INFO_FIELD].processed;

    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Convocatorias Minciencias'
            };
            setInvestigation({ hierarchy: hierarchy });
            requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);

    return (
        <Box sx={{ bgcolor: 'white', borderRadius:1 }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                <Timeline
                    data={formatTLGroups(group[constants.GROUP_CATEGORIES_FIELD], settings.language)}
                    handleClick={null}
                />
            </Stack>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Calls);