import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { constants } from '../../../../../constants';
import { getParams } from '../../../../../utils/utils';

function AuthorSeo(props) {
    const { data, dependence } = props;

    const author = data[constants.METRIC_AUTHORS_INFO_FIELD].processed;

    const name = author[constants.AUTHOR_NAME_FIELD];
    const email = author[constants.AUTHOR_EMAIL_FIELD];
    const gender = author[constants.AUTHOR_GENDER_FIELD];
    const state = author[constants.AUTHOR_STATE_FIELD];
    const image = author[constants.AUTHOR_IMAGE_FIELD];
    const job = `${author[constants.AUTHOR_POSITION_FIELD]} (${author[constants.AUTHOR_CONTRACT_FIELD]})`;
    const institution = author[constants.AUTHOR_INSTITUTION_FIELD];
    const campus = author[constants.AUTHOR_CAMPUS_FIELD];
    const faculty = author[constants.AUTHOR_FACULTY_FIELD];
    const uab = author[constants.AUTHOR_UAB_FIELD];
    const hIndexScopus = author[constants.AUTHOR_H_INDEX_SCOPUS_FIELD];
    const hIndexGoogleScholar = author[constants.AUTHOR_H_INDEX_GS_FIELD];
    const urlResearchGate = author[constants.AUTHOR_URL_RG_FIELD];
    const urlGoogleScholar = author[constants.AUTHOR_URL_GS_FIELD];
    const urlWos = author[constants.AUTHOR_URL_WS_FIELD];
    const urlCvlac = author[constants.AUTHOR_URL_CVLAC_FIELD];
    const urlOrcid = author[constants.AUTHOR_URL_ORCID_FIELD];
    const groups = data[constants.METRIC_GROUPS_FIELD].processed;
    const projects = data[constants.METRIC_PROJECTS_FIELD].processed;
    const products = data[constants.METRIC_PUBLICATIONS_FIELD].processed + data[constants.METRIC_THESIS_FIELD].processed;
    const seedbeds = data[constants.METRIC_SEEDBEDS_FIELD].processed;
    const laboratories = data[constants.METRIC_LABORATORIES_FIELD].processed;
    const mobilities = data[constants.METRIC_MOBILITIES_FIELD].processed;

    const title = `${name} - HORUS Visibilidad Científica y Tecnológica`;
    const description = `Perfil ${gender === 'F' ? 'de la docente investigadora' : 'del docente investigador'} ${state} ${name}, ${job} en la ${institution}, Sede ${campus}, ${faculty}, ${uab}. Índice H (Scopus): ${hIndexScopus}, Índice H (Google Scholar): ${hIndexGoogleScholar}. Cuenta con ${groups || 0} grupo(s) de investigación, ${projects || 0} proyecto(s), ${products || 0} publicación(es), ${seedbeds || 0} semillero(s), ${laboratories || 0} laboratorio(s) y ${mobilities || 0} movilidad(es).`;
    const keywords = [name, job, institution, campus, faculty, uab, 'índice h', 'índice h scopus', 'índice h google scholar', 'grupo de investigación', 'proyecto de investigación', 'publicación científica', 'semillero de investigación', 'laboratorio de investigación', 'movilidad académica'].join(', ');
    const url = ['https://horus.unal.edu.co' + getParams(dependence, name), 'resource', 'products'].join('/');

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'Person',
        'name': name,
        'jobTitle': job,
        'email': email,
        'gender': gender,
        'image': image,
        'sameAs': Object.values({ ...urlResearchGate, ...urlGoogleScholar, ...urlWos, ...urlCvlac, ...urlOrcid }).flat().filter(url => url),
        'url': url,
        'affiliation': {
            '@type': 'Organization',
            'name': institution,
        }
    };
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta name='author' content={name} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={image} />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='profile' />
            {urlResearchGate.length > 0 && <meta property='og:url' content={urlResearchGate[0]} />}
            {urlGoogleScholar.length > 0 && <meta property='og:url' content={urlGoogleScholar[0]} />}
            {urlWos.length > 0 && <meta property='og:url' content={urlWos[0]} />}
            {urlCvlac.length > 0 && <meta property='og:url' content={urlCvlac[0]} />}
            {urlOrcid.length > 0 && <meta property='og:url' content={urlOrcid[0]} />}
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <meta name='twitter:image' content={image} />
            <link rel='canonical' href={url} />
            <meta property='profile:username' content={name} />
            <meta property='profile:affiliation' content={institution} />
            <meta property='profile:gender' content={gender === 'F' ? 'female' : 'male'} />
            <script type='application/ld+json'>{JSON.stringify(jsonLd)}</script>
        </Helmet>
    );
};
const mapStateToProps = (state) => ({
    dependence: state.dependence
});
export default connect(mapStateToProps)(AuthorSeo);