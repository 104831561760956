import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Box, Card, Tooltip, IconButton, Avatar, Typography, Chip, Grid } from '@mui/material';
import { CalendarMonthTwoTone, Person3TwoTone, PersonOutlineTwoTone, MarkEmailReadTwoTone, PersonSearchTwoTone } from '@mui/icons-material';
import { formatDate, formatDependenciesName, getLeader } from '../../../utils/utils';
import { constants } from '../../../constants';

function CardSeedbed(props) {
    const { seedbed } = props;

    const { t } = useTranslation();
    const leader = getLeader(seedbed[constants.SEEDBEDS_MEMBERS_FIELD]);

    const information = [
        {
            key: seedbed[constants.SEEDBEDS_EMAIL_FIELD],
            label: t('components.card.cardSeedbed.email'),
            icon: <MarkEmailReadTwoTone />
        },
        {
            key: formatDate(seedbed[constants.SEEDBEDS_DATE_FIELD]),
            label: t('components.card.cardSeedbed.date'),
            icon: <CalendarMonthTwoTone />
        },
        {
            key: seedbed[constants.SEEDBEDS_STATE_FIELD],
            label: t('components.card.cardSeedbed.state'),
            icon: <PersonSearchTwoTone />
        }
    ];

    return (
        <Card
            elevation={4}
            sx={{
                borderRadius: 1,
                bgcolor: 'rgba(218, 218, 218, 0.28)',
                backdropFilter: 'blur(6px)',
                color: 'white',
                textShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
                pl: 4, pr: 3, pt: 3, pb: 3,
                width: '100%',
                height: '100%'
            }}
        >
            <Grid
                container
                direction={{ xs: 'column', sm: 'row' }}
                spacing={1}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    alignContent={'center'}
                >
                    <Typography variant='h5'
                        sx={{
                            lineHeight: 1,
                            marginBottom: '4px'
                        }}
                    >
                        {seedbed[constants.SEEDBEDS_NAME_FIELD]}
                    </Typography>
                    {information.map((info, key) => (
                        <Tooltip
                            key={key}
                            title={info.label + t('components.card.cardSeedbed.informationTooltip')}
                        >
                            <Stack
                                direction={'row'}
                                spacing={1}
                                alignContent={'center'}
                            >
                                {info.icon}
                                <Typography variant='body1'
                                    sx={{
                                        lineHeight: 1,
                                        wordBreak: 'break-word',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    {info.key}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    ))}
                    <Typography variant='h5'>
                        {t('components.card.cardSeedbed.justification')}
                    </Typography>
                    <Typography variant='body1'
                        sx={{
                            display: 'block',
                            lineHeight: 1,
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderLeft: '3px solid',
                            textAlign: 'justify',
                            marginBottom: 1,
                            mt: 1,
                        }}
                    >
                        {seedbed[constants.SEEDBEDS_JUSTIFICATION_FIELD]}
                    </Typography>
                    <Typography variant='h5'>
                        {t('components.card.cardSeedbed.objetive')}
                    </Typography>
                    <Typography variant='body1'
                        sx={{
                            display: 'block',
                            lineHeight: 1,
                            paddingLeft: 1,
                            paddingRight: 1,
                            borderLeft: '3px solid',
                            textAlign: 'justify',
                            marginBottom: 1,
                            mt: 1,
                        }}
                    >
                        {seedbed[constants.SEEDBEDS_OBJETIVE_FIELD]}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 'auto',
                        maxWidth: '100%',
                    }}
                >
                    <Typography variant='h5'>
                        {t('components.card.cardSeedbed.leader')}
                    </Typography>
                    <Stack
                        direction='row'
                        spacing={1.5}
                        alignItems='center'
                    >
                        <Avatar
                            sx={{
                                width: 60,
                                height: 60,
                                bgcolor: theme => theme.palette[leader[constants.AUTHOR_GENDER_FIELD] === 'F' ? 'female' : 'male'].main
                            }}
                        >
                            {leader[constants.AUTHOR_IMAGE_FIELD] ? (
                                <Box
                                    component='img'
                                    width='100%'
                                    src={
                                        leader[constants.AUTHOR_IMAGE_FIELD].includes('http')
                                            ? leader[constants.AUTHOR_IMAGE_FIELD]
                                            : require('../../../assets/images/' + leader[constants.AUTHOR_IMAGE_FIELD])
                                    }
                                />
                            ) : (
                                <Box
                                    component={
                                        leader[constants.AUTHOR_GENDER_FIELD] === 'F'
                                            ? Person3TwoTone
                                            : PersonOutlineTwoTone
                                    }
                                    width={40}
                                    height={40}
                                />
                            )}
                        </Avatar>
                        <Stack>
                            <Typography variant='body1' sx={{ fontWeight: 600, letterSpacing: 0.3 }}>
                                {leader[constants.AUTHOR_NAME_FIELD]}
                            </Typography>
                            <Typography variant='body2' >
                                {formatDependenciesName(leader[constants.AUTHOR_UAB_FIELD])}
                            </Typography>
                            <Typography variant='body2' sx={{ lineHeight: 0.8 }}>
                                {formatDependenciesName(leader[constants.AUTHOR_FACULTY_FIELD])}
                            </Typography>
                            <Typography variant='body2'>
                                {formatDependenciesName(leader[constants.AUTHOR_CAMPUS_FIELD])}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            textAlign: 'left',
                        }}>
                        <Typography variant='h5'>
                            {t('components.card.cardSeedbed.lines')}
                        </Typography>
                        <Box
                            sx={{
                                marginBottom: 0.5,
                            }}
                        >
                            {seedbed[constants.SEEDBEDS_LINES_FIELD].map((line, key) =>
                                <Chip
                                    key={line}
                                    label={
                                        <Typography variant='body2' sx={{
                                            p: 0.8,
                                            whiteSpace: 'normal',
                                            overflow: 'visible',
                                            textOverflow: 'clip',
                                            display: 'block',
                                            wordBreak: 'break-word',
                                            lineHeight: 1,
                                        }}>
                                            {line}
                                        </Typography>
                                    }
                                    sx={{
                                        mb: 0.4,
                                        mr: 0.4,
                                        color: 'white',
                                        bgcolor: 'rgba(0, 0, 0, 0.25)',
                                        height: 'auto',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', mb: { xs: 0, sm: 4 } }}>
                        <Typography variant='h5' mt='8px'>
                            {t('components.card.cardSeedbed.profiles')}
                        </Typography>
                        <Stack direction='row' spacing={1}>
                            {seedbed[constants.SEEDBEDS_URL_FIELD] && (
                                <Tooltip title={t('components.card.cardSeedbed.linkHermes')}>
                                    <IconButton href={seedbed[constants.SEEDBEDS_URL_FIELD]} target='_blank' rel='noreferrer' sx={{ p: 0 }}>
                                        <Avatar
                                            variant='rounded'
                                            src={require('../../../assets/images/horus/hermes.png')}
                                            sx={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Box>

                </Grid>
            </Grid>
        </Card>
    );
};
CardSeedbed.propTypes = {
    seedbed: PropTypes.object,
};
CardSeedbed.defaultProps = {
    seedbed: {},
};
export default CardSeedbed;