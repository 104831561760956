import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import CardGraphics from '../../../../components/card/CardGraphics';
import BarGradient from '../../../../components/graphics/BarGradient';
import ColumnStacked from '../../../../components/graphics/ColumnStacked';
import ColumnGroupStacked from '../../../../components/graphics/ColumnGroupStacked';
import BarGrouped from '../../../../components/graphics/BarGrouped';
import ChoroplethWorMap from '../../../../components/graphics/ChoroplethWorMap';
import MultiLine from '../../../../components/graphics/MultiLine';
import Column from '../../../../components/graphics/Column';
import { requestGet, requestPost } from '../../../../../utils/request';
import { getParamsRequest, metricsInv, formatMessage, formatCapitalize, objectAssignInv } from '../../../../../utils/utils';
import { constants, URL_API_INV, ACT_DEP } from '../../../../../constants';

function Products(props) {
    const { investigation, settings, setInvestigation, setSettings, isAuthor } = props;

    const { t } = useTranslation();
    const params = useParams();
    const activeHierarchy = investigation.hierarchy.hierarchy.hierarchy[2];
    const activeReports = activeHierarchy !== 'DEPENDENCE_AUTHOR_FIELD' && !investigation.search.search && ACT_DEP.REP;
    const activeSearch = 'query' in params;
    const tools = investigation.metrics.products[constants.METRIC_GENDER_THESIS_FIELD].tools.chart.value.value;

    const handleOnChange = (value, state) => {
        const newInvestigation = objectAssignInv(investigation, {
            metrics: { products: { [state]: value } },
        });
        newInvestigation.filters.rangeTime.type = true;
        setInvestigation(
            metricsInv(
                [state],
                investigation.metrics.products.source,
                investigation.hierarchy,
                newInvestigation,
                settings
            )
        );
    };

    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoProducts')) });
        if (investigation.hierarchy.metrics.products || investigation.search.search) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Publicaciones',
            };
            const paramsRequest = getParamsRequest(investigation.hierarchy.params);
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/products_metrics`, {
                    _id: investigation.hierarchy.metrics.products,
                    search: investigation.search.search,
                    record: hierarchy
                }),
                requestGet(`${URL_API_INV}/reports/gender`, paramsRequest, activeReports),
                requestGet(`${URL_API_INV}/reports/gender-thesis`, paramsRequest, activeReports),
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.products),
                            {
                                ...res[0].data,
                                [constants.METRIC_GENDER_THESIS_FIELD]: {
                                    gender: res[1].data[0],
                                    gender_thesis: res[2].data[0]
                                },
                            },
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.products, investigation.search.search, settings.language]);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.amountPublicationsByType')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.amountPublicationsByTypeInfo'),
                            gif: require('../../../../../assets/images/animation/tipo_publicaciones.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_TYPE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TYPE_FIELD),
                            columns: {
                                type: t('dependence.investigation.productsMain.publicationType'),
                                value: t('dependence.investigation.publicationsAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}

                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_TYPE_FIELD].processed,
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6} >
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.amountPublicationsByTypeAndYear')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.amountPublicationsByTypeAndYearInfo'),
                            gif: require('../../../../../assets/images/animation/tipo_publicaciones_year.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_TYPE_YEAR_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TYPE_YEAR_FIELD, investigation),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.productsMain.publicationType'),
                                value: t('dependence.investigation.publicationsAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_TYPE_YEAR_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.publicationsAmount'),
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12}>
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.amountCollaborationsByCountry')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.amountCollaborationsByCountryInfo'),
                            gif: require('../../../../../assets/images/animation/mapa.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_COUNTRIES_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_COUNTRIES_FIELD, investigation),
                            columns: {
                                type: t('dependence.investigation.country'),
                                value: t('dependence.investigation.productsMain.collaborationsAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ChoroplethWorMap
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_COUNTRIES_FIELD].processed,
                                width: 1000,
                                height: 500,
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                {activeHierarchy !== 'DEPENDENCE_AUTHOR_FIELD' && (
                    <Grid item xs={12} md={isAuthor ? 12 : 6}>
                        <CardGraphics
                            title={t('dependence.investigation.productsMain.articleRate')}
                            tools={{
                                usability: true,
                                rangeTime: true,
                                gender: false,
                                type: false,
                                accumulate: true,
                                wordCloud: false,
                                graph: false,
                                chart: false,
                                download: true,
                                dialog: true,
                                dataTable: true
                            }}
                            infoDialog={{
                                label: t('dependence.investigation.productsMain.articleRateInfo'),
                                gif: require('../../../../../assets/images/animation/tasa de articulos.gif'),
                                notice: ''
                            }}
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_AVERAGE_FIELD],
                                onChange: (value) => handleOnChange(value, constants.METRIC_AVERAGE_FIELD, investigation),
                                columns: {
                                    axis: t('dependence.investigation.year'),
                                    value: t('dependence.investigation.productsMain.articleRate'),
                                },
                                formatValue: 'float',
                                sortField: 'axis'
                            }}
                        >
                            <Column
                                infoChart={{
                                    data: investigation.metrics.products[constants.METRIC_AVERAGE_FIELD].processed,
                                    colorGender: false,
                                    axis: {
                                        xAxis: t('dependence.investigation.year'),
                                        yAxis: t('dependence.investigation.productsMain.articleRate'),
                                    },
                                    formatValue: 'float',
                                    label: false
                                }}
                            />
                        </CardGraphics>
                    </Grid>
                )}
                <Grid item xs={12} md={isAuthor ? 0 : 6}>
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.amountPublicationsNationalAndInternational')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: true,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.amountPublicationsNationalAndInternationalInfo'),
                            gif: require('../../../../../assets/images/animation/nacional.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_JOURNALS_CLASS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_JOURNALS_CLASS_FIELD, investigation),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.productsMain.publicationType'),
                                value: t('dependence.investigation.publicationsAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_JOURNALS_CLASS_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.publicationsAmount'),
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12}>
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.scimagoRankingOverTime')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.scimagoRankingOverTimeInfo'),
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_SCIMAGO_CLASS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_SCIMAGO_CLASS_FIELD, investigation),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.classification'),
                                value: t('dependence.investigation.productsMain.amountPublicationsByScimago'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_SCIMAGO_CLASS_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.productsMain.amountPublicationsByScimago'),
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.topPublicationsByJournal')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.topPublicationsByJournalInfo'),
                            gif: require('../../../../../assets/images/animation/top_revistas.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_JOURNALS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_JOURNALS_FIELD, investigation),
                            columns: {
                                type: t('dependence.investigation.journals'),
                                value: t('dependence.investigation.publicationsAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_JOURNALS_FIELD].processed.slice(0, 20),
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.productsMain.topPublicationsByInstitution')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: activeHierarchy === 'DEPENDENCE_AUTHOR_FIELD' ? false : true,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: true,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: t('dependence.investigation.productsMain.topPublicationsByInstitutionInfo'),
                            gif: require('../../../../../assets/images/animation/top_instituciones.gif'),
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.products[constants.METRIC_INSTITUTIONS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_INSTITUTIONS_FIELD, investigation),
                            columns: {
                                type: t('dependence.investigation.institutions'),
                                value: t('dependence.investigation.publicationsAmount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_INSTITUTIONS_FIELD].processed.slice(0, 20),
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            {(!['DEPENDENCE_UAB_FIELD', 'DEPENDENCE_AUTHOR_FIELD'].includes(activeHierarchy) && !activeSearch) && (
                <Grid container spacing={3} sx={{ mt: 0.5 }}>
                    <Grid item xs={12}>
                        <CardGraphics
                            title={t('dependence.investigation.productsMain.articleRatePublishedBy') + constants.DEPENDENCE_INFO_FIELD[activeHierarchy]?.['infoLevel_' + settings.language][1]}
                            tools={{
                                usability: true,
                                rangeTime: false,
                                gender: false,
                                type: false,
                                accumulate: false,
                                wordCloud: false,
                                graph: false,
                                chart: false,
                                download: true,
                                dialog: true,
                                dataTable: true
                            }}
                            infoDialog={{
                                label: t('dependence.investigation.productsMain.articleRatePublishedByInfo'),
                                gif: require('../../../../../assets/images/animation/tasa por subnivel.gif'),
                                notice: ''
                            }}
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_RATE_FIELD],
                                onChange: (value) => handleOnChange(value, constants.METRIC_RATE_FIELD, investigation),
                                columns: {
                                    axis: t('dependence.investigation.year'),
                                    type: t('dependence.investigation.dependence'),
                                    value: t('dependence.investigation.productsMain.articleRate'),
                                },
                                formatValue: 'float',
                                sortField: 'axis'
                            }}
                        >
                            <MultiLine
                                infoChart={{
                                    data: investigation.metrics.products[constants.METRIC_RATE_FIELD].processed,
                                    colorGender: false,
                                    axis: {
                                        xAxis: t('dependence.investigation.year'),
                                        yAxis: t('dependence.investigation.productsMain.articleRate'),
                                    },
                                }}
                            />
                        </CardGraphics>
                    </Grid>
                </Grid>
            )}
            {(!['DEPENDENCE_UAB_FIELD', 'DEPENDENCE_AUTHOR_FIELD'].includes(activeHierarchy) && !activeSearch) && (
                <Grid container spacing={3} sx={{ mt: 0.5 }}>
                    <Grid item xs={12}>
                        <CardGraphics
                            title={t('dependence.investigation.productsMain.productionBy') + constants.DEPENDENCE_INFO_FIELD[activeHierarchy]?.['infoLevel_' + settings.language][1]}
                            tools={{
                                usability: true,
                                rangeTime: false,
                                gender: false,
                                type: false,
                                accumulate: false,
                                wordCloud: false,
                                graph: false,
                                chart: false,
                                download: true,
                                dialog: true,
                                dataTable: true
                            }}
                            infoDialog={{
                                label: t('dependence.investigation.productsMain.productionByInfo'),
                                gif: require('../../../../../assets/images/animation/produccion.gif'),
                                notice: ''
                            }}
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_PRODUCTION_FIELD],
                                onChange: (value) => handleOnChange(value, constants.METRIC_PRODUCTION_FIELD, investigation),
                                columns: {
                                    group: formatCapitalize(constants.DEPENDENCE_INFO_FIELD[activeHierarchy]?.['infoLevel_' + settings.language][0]),
                                    type: t('dependence.investigation.productsMain.productionType'),
                                    value: t('dependence.investigation.amount'),
                                },
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        >
                            <BarGrouped
                                infoChart={{
                                    data: investigation.metrics.products[constants.METRIC_PRODUCTION_FIELD].processed,
                                    axis: {
                                        xAxis: t('dependence.investigation.productsMain.publicationsAndCitations'),
                                        yAxis: formatCapitalize(constants.DEPENDENCE_INFO_FIELD[activeHierarchy]?.['infoLevel_' + settings.language][1]),
                                    },
                                }}
                            />
                        </CardGraphics>
                    </Grid>
                </Grid>
            )}
            {(activeReports && !activeSearch) && (
                <Grid container spacing={3} sx={{ mt: 0.5 }}>
                    <Grid item xs={12}>
                        <CardGraphics
                            title={t('dependence.investigation.productsMain.amountThesisDirectors')}
                            tools={{
                                usability: true,
                                rangeTime: false,
                                gender: false,
                                type: false,
                                accumulate: false,
                                wordCloud: false,
                                graph: false,
                                chart: true,
                                download: true,
                                dialog: true,
                                dataTable: true
                            }}
                            infoDialog={{
                                label: t('dependence.investigation.productsMain.amountThesisDirectorsInfo'),
                                gif: require('../../../../../assets/images/animation/tipologia_proyecto_genero.gif'),
                                notice: ''
                            }}
                            infoChart={{
                                data: investigation.metrics.products[constants.METRIC_GENDER_THESIS_FIELD],
                                onChange: (value) => handleOnChange(value, constants.METRIC_GENDER_THESIS_FIELD, investigation),
                                columns: {
                                    axis: t('dependence.investigation.productsMain.modalityType'),
                                    type: t('dependence.investigation.gender'),
                                    value: t('dependence.investigation.productsMain.amountDirectors')
                                },
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        >
                            <ColumnGroupStacked
                                infoChart={{
                                    data: investigation.metrics.products[constants.METRIC_GENDER_THESIS_FIELD].processed,
                                    colorGender: true,
                                    axis: {
                                        xAxis: t('dependence.investigation.productsMain.modalityType'),
                                        yAxis: tools === 'quantity' ? t('dependence.investigation.productsMain.amountDirectorsQuantity') : t('dependence.investigation.productsMain.amountDirectorsProportion'),
                                    },
                                    formatValue: tools === 'proportion' ? 'percent' : ''
                                }}
                            />
                        </CardGraphics>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

Products.propTypes = {
    isAuthor: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);