import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

function Mobilities(props) {
    
    return (
        <Box>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT',
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Mobilities);