import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Container, Grid, Paper, Stack } from '@mui/material';
import { ScreenSearchDesktopTwoTone, GroupsTwoTone, ContentPasteSearchTwoTone, QueryStatsTwoTone, WifiFindTwoTone, Diversity2TwoTone, BiotechTwoTone, AirlineStopsTwoTone } from '@mui/icons-material';
import AppBar from '../../../components/appbar/Appbar';
import Widgets from '../../../components/widgets/Widgets';
import CardAuthor from '../../../components/card/CardAuthor';
import InvRangeTime from '../../../components/range/InvRangeTime';
import Products from './panels/Products';
import Projects from './panels/Projects';
import Areas from './panels/Areas';
import ProductsList from './panels/ProductsList';
import GroupsAuthor from './panels/GroupsAuthor';
import SeedbedsAuthor from './panels/SeedbedsAuthor';
import Laboratories from './panels/Laboratories';
import Mobilities from './panels/Mobilities';
import AuthorSeo from './seo/AuthorSeo';
import { filterDependenciesInv, metricsInv, formatMessage, getParamsRoute } from '../../../../utils/utils';
import { requestPost } from '../../../../utils/request';
import { constants, URL_API_INV } from '../../../../constants';

function Author(props) {
    const { dependence, investigation, settings, setInvestigation, setSettings } = props;
    const [metric, setMetric] = useState('');

    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const widgets = [
        {
            title: t('dependence.investigation.products'),
            total: investigation.metrics.general[constants.METRIC_PUBLICATIONS_FIELD].processed + investigation.metrics.general[constants.METRIC_THESIS_FIELD].processed,
            sub_info: [
                {
                    title: t('dependence.investigation.citations'),
                    total: investigation.metrics.general[constants.METRIC_CITATIONS_FIELD].processed,
                }
            ],
            config: {
                step: 'products',
                icon: <ContentPasteSearchTwoTone />,
                filter: true
            }
        },
        {
            title: t('dependence.investigation.groups'),
            total: investigation.metrics.general[constants.METRIC_GROUPS_FIELD].processed,
            sub_info: [],
            config: {
                step: 'groups',
                icon: < GroupsTwoTone />,
                filter: false
            }
        },
        {
            title: t('dependence.investigation.projects'),
            total: investigation.metrics.general[constants.METRIC_PROJECTS_FIELD].processed,
            sub_info: [],
            config: {
                step: 'projects',
                icon: <QueryStatsTwoTone />,
                filter: true,
            }
        },
        {
            title: t('dependence.investigation.themesAndWorkAreas'),
            total: 0,
            sub_info: [],
            config: {
                step: 'themesAndWorkAreas',
                icon: <WifiFindTwoTone />,
                filter: false
            }
        },
        {
            title: t('dependence.investigation.productsList'),
            total: investigation.metrics.general[constants.METRIC_PUBLICATIONS_LIST_FIELD].processed.length,
            sub_info: [],
            config: {
                step: 'productsList',
                icon: <ScreenSearchDesktopTwoTone />,
                filter: false
            }
        },
        // {
        //     title: t('dependence.investigation.seedbeds'),
        //     total: investigation.metrics.general[constants.METRIC_SEEDBEDS_FIELD].processed,
        //     sub_info: [],
        //     config: {
        //         step: 'seedbeds',
        //         icon: <Diversity2TwoTone />,
        //         filter: true
        //     }
        // },
        // {
        //     title: t('dependence.investigation.laboratories'),
        //     total: investigation.metrics.general[constants.METRIC_LABORATORIES_FIELD].processed,
        //     sub_info: [],
        //     config: {
        //         step: 'laboratories',
        //         icon: <BiotechTwoTone />,
        //         filter: true
        //     }
        // },
        // {
        //     title: t('dependence.investigation.mobilities'),
        //     total: investigation.metrics.general[constants.METRIC_MOBILITIES_FIELD].processed,
        //     sub_info: [],
        //     config: {
        //         step: 'mobilities',
        //         icon: <AirlineStopsTwoTone />,
        //         filter: true
        //     }
        // }
    ];

    const handleRoute = (resource) => {
        params.resource = resource;
        const route = getParamsRoute(params);
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        const hierarchy = filterDependenciesInv('author', dependence, params, investigation, location, widgets, navigate);
        if (hierarchy.metrics) {
            if (metric !== hierarchy.metrics.general) {
                setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoProfessor')) });
                setMetric(hierarchy.metrics.general);
                Promise.all([
                    requestPost(`${URL_API_INV}/investigation/general_metrics`, {
                        _id: hierarchy.metrics.general,
                        record: hierarchy
                    }),
                    requestPost(`${URL_API_INV}/investigation/author`, {
                        _id: hierarchy.metrics.author
                    }),
                ])
                    .then(res => {
                        setInvestigation(
                            metricsInv(
                                Object.keys(investigation.metrics.general),
                                { ...res[0].data, ...res[1].data },
                                hierarchy,
                                investigation,
                                settings
                            )
                        );
                        setSettings({ backdrop: formatMessage(false, '', '') });
                    })
                    .catch(err => {
                        setSettings({
                            backdrop: formatMessage(false, '', ''),
                            snackbar: formatMessage(true, 'error', err),
                        });
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, dependence]);
    return (
        <Box sx={{ backgroundColor: 'white' }} >
            <AuthorSeo data={investigation.metrics.general} />
            <AppBar hierarchy='research' params={params} value={params.author}>
                <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignItems='center'
                    sx={{ width: { xs: '100%', sm: '90%', md: '100%', lg: '90%' }, ml: { sm: '1%', md: '0%', lg: '5%' } }}
                >
                    <Grid item xs={12} md={6}>
                        <CardAuthor
                            author={investigation.metrics.general[constants.METRIC_AUTHORS_INFO_FIELD].processed}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <Widgets
                            type='column'
                            widgets={widgets}
                            stepTab={params.resource}
                            handleStepTab={(resource) => handleRoute(resource)}
                        />
                    </Grid>
                </Grid>
            </AppBar>
            <Container
                component='main'
                maxWidth='xl'
            >
                <Stack
                    direction='row'
                    justifyContent='right'
                    alignItems='right'
                    sx={{ mt: 1 }}
                >
                    <InvRangeTime />
                </Stack>
                <Stack direction={{ md: 'column' }} sx={{ mt: 1, pb: 3 }} spacing={3}>
                    <Paper elevation={4} sx={{ backgroundColor: '#DDDDDD', boxShadow: 0 }}>
                        <TabContext value={params.resource}>
                            <TabPanel value='products'>
                                <Products isAuthor={true} />
                            </TabPanel>
                            <TabPanel value='projects'>
                                <Projects />
                            </TabPanel>
                            <TabPanel value='themesAndWorkAreas'>
                                <Areas />
                            </TabPanel>
                            <TabPanel value='productsList'>
                                <ProductsList
                                    author={[]}
                                    order={{
                                        type: 'date',
                                        variant: 'dsc',
                                        fields: ['date', 'citations']
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value='groups'>
                                <GroupsAuthor
                                    author={investigation.metrics.general[constants.METRIC_AUTHORS_INFO_FIELD].processed}
                                    params={params}
                                />
                            </TabPanel>
                            <TabPanel value='seedbeds'>
                                <SeedbedsAuthor
                                    author={investigation.metrics.general[constants.METRIC_AUTHORS_INFO_FIELD].processed}
                                    params={params}
                                />
                            </TabPanel>
                            <TabPanel value='laboratories'>
                                <Laboratories />
                            </TabPanel>
                            <TabPanel value='mobilities'>
                                <Mobilities />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Stack>
            </Container>
        </Box>
    );
}
const mapStateToProps = (state) => ({
    dependence: state.dependence,
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Author);