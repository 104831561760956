import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-map-gl/maplibre';
import DeckGL from '@deck.gl/react';
import { ArcLayer } from '@deck.gl/layers';
import { Box, Button, Typography } from '@mui/material';
import WorldMapBCLight from '../maps/WorldMapBCLight.json';

const colorMap = [
    '#BBDEFB',
    '#90CAF9',
    '#64B5F6',
    '#42A5F5',
    '#2196F3',
    '#1E88E5',
    '#1976D2',
    '#0D47A1',
    '#103381'
];

function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

function getColor(value, scaleMax, activeColorMap) {
    const normalizedValue = Math.min(value, scaleMax) / scaleMax;
    const index = Math.floor(normalizedValue * (activeColorMap.length - 1));
    return hexToRgb(activeColorMap[index]);
}

function ConnectionsWorMap(props) {
    const { infoChart } = props;
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [layers, setLayers] = useState([]);
    const [scaleMax, setScaleMax] = useState(1);
    const [activeColorMap, setActiveColorMap] = useState(colorMap);
    const [group, setGroup] = useState(false);

    const getTooltip = ({ object }) => {
        return object
            ? `Ruta: ${object.origin} - ${object.destination}\nMovilidades: ${object.value}\n${object.group ? 'Presione para ver las conexiones por ciudad' : ''}`
            : null;
    };

    const handleClick = ({ object }) => {
        if (object?.group) {
            setData(object.group);
            setGroup(true);
        }
    };

    const handleReset = () => {
        setData(infoChart.data);
        setGroup(false);
    };

    const legendValues = activeColorMap.map((color, i) => {
        const minValue = Math.round((i / activeColorMap.length) * scaleMax);
        const maxLegendValue = Math.round(((i + 1) / activeColorMap.length) * scaleMax);
        return { color, range: `${minValue} - ${maxLegendValue}` };
    });

    useEffect(() => {
        setData(infoChart.data);
    }, [infoChart.data]);

    useEffect(() => {
        if (data.length > 0) {
            const values = data.map(item => item.value);
            const maxValue = Math.max(...values);
            let colorsToUse;

            if (maxValue < (colorMap.length * 2)) {
                colorsToUse = Math.ceil(maxValue / 2);
                colorsToUse = Math.max(colorsToUse, 1);
            } else {
                colorsToUse = colorMap.length;
            }
            const adjustedColorMap = colorMap.slice(0, colorsToUse);
            const adjustedScaleMax = maxValue;

            setScaleMax(adjustedScaleMax);
            setActiveColorMap(adjustedColorMap);

            const newLayers = [
                new ArcLayer({
                    id: 'arc',
                    data: data,
                    pickable: true,
                    getSourcePosition: d => d.location[0],
                    getTargetPosition: d => d.location[1],
                    getSourceColor: d => getColor(d.value, adjustedScaleMax, adjustedColorMap),
                    getTargetColor: d => getColor(d.value, adjustedScaleMax, adjustedColorMap),
                    getWidth: 2.5
                })
            ];
            setLayers(newLayers);
        }
    }, [data]);

    return (
        <Box sx={{ height: 480, position: 'relative' }}>
            <DeckGL
                layers={layers}
                initialViewState={{
                    longitude: 0,
                    latitude: 0,
                    zoom: 1,
                    minZoom: 0,
                    pitch: 20,
                    bearing: 0
                }}
                controller={true}
                getTooltip={getTooltip}
                onClick={handleClick}
                style={{ width: '100%', height: 450 }}
            >
                <Map reuseMaps mapStyle={WorldMapBCLight} />
            </DeckGL>
            <Box sx={{ position: 'absolute', bottom: 60, right: 10, bgcolor: 'white', p: 1, borderRadius: 1, boxShadow: 3 }}>
                <Typography variant='body2'>{t('components.graphics.Movilidades')}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    {legendValues.map(({ color, range }, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 20, height: 10, backgroundColor: color, mr: 1 }} />
                            <Typography variant='body2'>{range}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            {group && (
                <Button onClick={handleReset} variant='contained' sx={{ position: 'absolute', top: 10, right: 10 }}>
                    {t('components.graphics.reset')}
                </Button>
            )}
        </Box>
    );
}
ConnectionsWorMap.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.array
    })
};
export default ConnectionsWorMap;