import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box, Stack, IconButton, useMediaQuery } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import Widget from './Widget';

function Widgets(props) {
    const { type, widgets, stepTab, handleStepTab } = props;

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));

    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '-50px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }
                }}
            >
                <ArrowBackIosNew fontSize='small' />
            </IconButton>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '-50px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }
                }}
            >
                <ArrowForwardIos fontSize='small' />
            </IconButton>
        );
    };

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: isTablet ? 3 : 5,
        slidesToScroll: isTablet ? 3 : 5,
        initialSlide: widgets.findIndex(widget => widget.config.step === stepTab) / 2,
        appendDots: dots => (
            <Box
                component='ul'
                sx={{
                    margin: -1,
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    listStyle: 'none',
                    '& li button:before': {
                        color: 'white'
                    },
                    '& li.slick-active button:before': {
                        color: 'white'
                    }
                }}
            >
                {dots}
            </Box>
        ),
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    return (
        <Box
            className='slider-container'
            sx={{ position: 'relative', padding: type === 'column' || type === 'column-2' || isMobile ? '0 0' : '0 70px' }}
        >
            {type === 'column' || type === 'column-2' || isMobile ? (
                <Stack
                    direction='column'
                    spacing={type === 'column-2' || isMobile ? 1 : 0}
                    sx={type === 'column' && !isMobile ? {
                        gap: 1,
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        '& :nth-of-type(1):nth-last-of-type(odd)': { gridColumn: 'span 2' }
                    } : {}}
                >
                    {widgets.map((widget, key) => (
                        <Widget
                            key={key}
                            title={widget.title}
                            total={widget.total}
                            sub_info={widget.sub_info}
                            config={widget.config}
                            stepTab={stepTab}
                            handleStepTab={(stepTab) => handleStepTab(stepTab)}
                        />
                    ))}
                </Stack>
            ) : (
                <Slider {...settings}>
                    {widgets.map((widget, key) => (
                        <Box key={key} sx={{ padding: '0 3px' }}>
                            <Widget
                                title={widget.title}
                                total={widget.total}
                                sub_info={widget.sub_info}
                                config={widget.config}
                                stepTab={stepTab}
                                handleStepTab={handleStepTab}
                            />
                        </Box>
                    ))}
                </Slider>
            )}
        </Box>
    );
}
Widgets.propTypes = {
    type: PropTypes.string,
    widgets: PropTypes.array,
    stepTab: PropTypes.string,
    handleStepTab: PropTypes.func
};
export default Widgets;