import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getParams, formatDependenciesName } from '../../../../../utils/utils';

function FacultySeo(props) {
    const { data, dependence } = props;

    const institution = data.institution;
    const campus = data.campus;
    const faculty = formatDependenciesName(data.faculty);

    const title = `${faculty} - HORUS Visibilidad Científica y Tecnológica`;
    const description = `Perfil de la ${faculty} en la ${institution}, Sede ${campus}.`;
    const keywords = [institution, campus, faculty, 'grupos de investigación', 'proyectos de investigación', 'publicaciones científicas', 'semilleros de investigación', 'laboratorios de investigación', 'movilidades académicas'].join(', ');
    const url = ['https://horus.unal.edu.co' + getParams(dependence, faculty), 'resource', 'products'].join('/');

    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='profile' />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <link rel='canonical' href={url} />
        </Helmet>
    );
};
const mapStateToProps = (state) => ({
    dependence: state.dependence
});
export default connect(mapStateToProps)(FacultySeo);