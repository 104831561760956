import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardHeader, Avatar, Typography, Stack, CardContent, Chip, Button, Grid } from '@mui/material';
import { SupervisedUserCircleTwoTone, FingerprintTwoTone, EventAvailableTwoTone, Diversity2TwoTone, InfoOutlined, EmailTwoTone } from '@mui/icons-material';
import Tooltip from '../../../../components/Tooltip';
import { requestPost } from '../../../../../utils/request';
import { formatDateMonthYear, sortedArrayValue, getParamsRoute, getParamsRouteString, getPosition } from '../../../../../utils/utils';
import { COLORS_STATE } from '../../../../../assets/theme/palette';
import { constants, URL_API_INV } from '../../../../../constants';

function SeedbedsAuthor(props) {
    const { author, params, investigation, setMetricsDefault, setInvestigation } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const seedbeds = getPosition(author, t, constants.AUTHOR_SEEDBEDS_FIELD, constants.SEEDBEDS_MEMBERS_FIELD);

    const handleSeedbed = (seedbed) => {
        setMetricsDefault();
        delete params.resource;
        params.seedbed = seedbed[constants.SEEDBEDS_NAME_FIELD];
        params.resource = getParamsRouteString('members');
        const route = getParamsRoute(params);
        navigate(route, { state: { key: 'hierarchy' } });
    };

    useEffect(() => {
        if (investigation.hierarchy.stepKey) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Grupos de Investigación'
            };
            setInvestigation({ hierarchy: hierarchy });
            requestPost(`${URL_API_INV}/usability/resource`, { record: hierarchy });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.stepKey]);

    return (
        <Box sx={{ m: 1 }}>
            <Grid container spacing={3}>
                {seedbeds.length === 0 ? (
                    <Grid item xs={12}>
                        <Card sx={{ width: '100%' }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: COLORS_STATE['No'] }}>
                                        <Diversity2TwoTone />
                                    </Avatar>
                                }
                                title={
                                    <Typography variant='h6'>
                                        {t('dependence.investigation.seedbedsMain.noSeedbeds')}
                                    </Typography>
                                }
                            />
                        </Card>
                    </Grid>
                ) : (
                    sortedArrayValue(seedbeds, constants.AUTHOR_POSITION_FIELD, t('components.graphics.Líder')).map((seedbed, key) => {
                        return (
                            <Grid item xs={12} sm={6} key={key}>
                                <Card elevation={4}>
                                    <CardHeader
                                        avatar={
                                            <Tooltip title={`${t('dependence.investigation.seedbedsMain.statusInfo')}: ${t('components.graphics.' + seedbed[constants.SEEDBEDS_STATE_FIELD])}`}>
                                                <Avatar sx={{ bgcolor: COLORS_STATE[seedbed[constants.SEEDBEDS_STATE_FIELD]] }}>
                                                    <Diversity2TwoTone />
                                                </Avatar>
                                            </Tooltip>
                                        }
                                        title={
                                            <Typography variant='h6'>
                                                {seedbed[constants.SEEDBEDS_NAME_FIELD]}
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography variant='body1' color='text.secondary'>
                                                <EventAvailableTwoTone /> {formatDateMonthYear(seedbed[constants.SEEDBEDS_DATE_FIELD])}
                                                <EmailTwoTone sx={{ ml: 1 }} /> {seedbed[constants.SEEDBEDS_EMAIL_FIELD]}
                                            </Typography>
                                        }
                                    />
                                    <CardContent sx={{ ml: 2, pt: 0 }}>
                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                            <Chip
                                                icon={<SupervisedUserCircleTwoTone /> }
                                                label={
                                                    <Typography variant='body1' color='text.secondary'>
                                                        {t('dependence.investigation.seedbedsMain.memberType')}: {seedbed[constants.AUTHOR_POSITION_FIELD]}
                                                    </Typography>
                                                }
                                                variant='outlined'
                                            />
                                            <Chip
                                                icon={<FingerprintTwoTone />}
                                                label={
                                                    <Typography variant='body1' color='text.secondary'>
                                                        {t('dependence.investigation.seedbedsMain.hermesId')}: {seedbed[constants.SEEDBEDS_ID_FIELD]}
                                                    </Typography>
                                                }
                                                variant='outlined'
                                            />
                                        </Stack>
                                        <Button
                                            sx={{
                                                mt: 2,
                                                bgcolor: theme => theme.palette['green'].main,
                                                color: 'white',
                                                p: 1,
                                                pr: 2,
                                                pl: 2,
                                                '&:hover': {
                                                    bgcolor: theme => theme.palette['green'].light,
                                                },
                                                '&:focus': {
                                                    outline: 'transparent',
                                                },
                                            }}
                                            startIcon={<InfoOutlined />}
                                            onClick={() => handleSeedbed(seedbed)}
                                        >
                                            <Typography variant='body1'>
                                                {t('dependence.investigation.seedbedsMain.information')}
                                            </Typography>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                )}
            </Grid>
        </Box>
    );
};
const mapStateToProps = (state) => ({
    investigation: state.investigation,
});
const mapDispatchToProps = (dispatch) => ({
    setMetricsDefault() {
        dispatch({
            type: 'SET_METRICS_DEFAULT',
        });
    },
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(SeedbedsAuthor);