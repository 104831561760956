import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Typography, Stack, Box, Button } from '@mui/material';
import { ManageHistoryTwoTone } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import { formatNumber } from '../../../utils/utils';

const RootStyle = styled(Button)(() => ({
    color: 'white',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: '130px'
}));

const IconWrapperStyle = styled('div')(() => ({
    top: 12,
    right: 12,
    width: 25,
    height: 25,
    position: 'absolute'
}));

function Widget(props) {
    const { title, total, sub_info, config, stepTab, handleStepTab } = props;

    const { t } = useTranslation();

    return (
        <RootStyle
            sx={{
                bgcolor: config.step === stepTab ? theme => theme.palette.green.main : theme => theme.palette.darkerBlue.main,
                border: config.step === stepTab ? 3 : 0,
                borderRadius: 1,
                '&:hover': {
                    bgcolor: theme => theme.palette.green.main,
                },
                '&:focus': {
                    outline: 'transparent',
                }
            }}
            onClick={() => handleStepTab(config.step)}
        >
            <Stack
                direction='row'
                textAlign='left'
            >
                <Stack
                    sx={{ p: 1.5, alignItems: 'flex-start' }}
                >
                    {config.icon && (
                        <Box color='white' paddingLeft={0.25}>
                            {cloneElement(config.icon, { fontSize: 'large' })}
                        </Box>
                    )}
                    {title && (
                        <Typography variant='subtitle1' lineHeight={1}>{title}</Typography>
                    )}
                    {total > 0 && (
                        <Typography sx={{ fontSize: 30, fontWeight: 600, lineHeight: 1 }}>
                            {formatNumber(total)}
                        </Typography>
                    )}
                    {sub_info.length > 0 && (
                        sub_info.map((info, key) => (
                            <Stack key={key} direction='row' spacing={1}>
                                <Typography sx={{ typography: 'body1' }}>{info.title}</Typography>
                                <Typography sx={{ typography: 'body1' }}>{formatNumber(info.total)}</Typography>
                            </Stack>
                        ))
                    )}
                </Stack>
                {config.filter && (
                    <Tooltip title={t('components.widgets.widget.tooltip')}>
                        <IconWrapperStyle sx={{ color: 'white' }}>
                            <ManageHistoryTwoTone />
                        </IconWrapperStyle>
                    </Tooltip>
                )}
            </Stack>
        </RootStyle>
    );
};
Widget.propTypes = {
    title: PropTypes.string,
    total: PropTypes.number,
    sub_info: PropTypes.array,
    config: PropTypes.object,
    stepTab: PropTypes.string,
    handleStepTab: PropTypes.func
};
Widget.defaultProps = {
    title: '',
    sub_info: [],
    config: {},
    stepTab: '',
    handleStepTab: () => { }
};
export default Widget;