import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import CardGraphics from '../../../../components/card/CardGraphics';
import ConnectionsWorMap from '../../../../components/graphics/ConnectionsWorMap';
import ColumnGroup from '../../../../components/graphics/ColumnGroup';
import BarGradient from '../../../../components/graphics/BarGradient';
import ColumnStacked from '../../../../components/graphics/ColumnStacked';
import { requestPost } from '../../../../../utils/request';
import { metricsInv, formatMessage, objectAssignInv } from '../../../../../utils/utils';
import { constants, URL_API_INV } from '../../../../../constants';

function Mobilities(props) {
    const { investigation, settings, setInvestigation, setSettings } = props;

    const { t } = useTranslation();

    const handleOnChange = (value, state) => {
        const newInvestigation = objectAssignInv(investigation, {
            metrics: { mobilities: { [state]: value } },
        });
        newInvestigation.filters.rangeTime.type = true;
        setInvestigation(
            metricsInv(
                [state],
                investigation.metrics.mobilities.source,
                investigation.hierarchy,
                newInvestigation,
                settings
            )
        );
    };

    useEffect(() => {
        setSettings({ backdrop: formatMessage(true, '', t('dependence.investigation.consultingInfoMobilities')) });
        if (investigation.hierarchy.metrics.mobilities || investigation.search.search) {
            const hierarchy = {
                ...investigation.hierarchy,
                resource: 'Movilidades'
            };
            Promise.all([
                requestPost(`${URL_API_INV}/investigation/mobilities_metrics`, {
                    _id: investigation.hierarchy.metrics.mobilities,
                    search: investigation.search.search,
                    record: hierarchy
                }),
            ])
                .then(res => {
                    setInvestigation(
                        metricsInv(
                            Object.keys(investigation.metrics.mobilities),
                            res[0].data,
                            hierarchy,
                            investigation,
                            settings
                        )
                    );
                    setSettings({ backdrop: formatMessage(false, '', '') });
                })
                .catch(err => {
                    setSettings({
                        backdrop: formatMessage(false, '', ''),
                        snackbar: formatMessage(true, 'error', err),
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investigation.hierarchy.metrics.mobilities, investigation.search.search, settings.language]);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.mobilitiesMain.destinationsTeacher')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: {
                                processed: investigation.metrics.mobilities[constants.METRIC_DESTINATIONS_TEACHER_FIELD].processed.flatMap(item => item.group),
                                tools: investigation.metrics.mobilities[constants.METRIC_DESTINATIONS_TEACHER_FIELD].tools
                            },
                            onChange: (value) => handleOnChange(value, constants.METRIC_DESTINATIONS_TEACHER_FIELD, investigation),
                            columns: {
                                originCountry: t('dependence.investigation.mobilitiesMain.originCountry'),
                                originCity: t('dependence.investigation.mobilitiesMain.originCity'),
                                destinationCountry: t('dependence.investigation.mobilitiesMain.destinationCountry'),
                                destinationCity: t('dependence.investigation.mobilitiesMain.destinationCity'),
                                value: t('dependence.investigation.mobilitiesMain.amount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ConnectionsWorMap
                            infoChart={{
                                data: investigation.metrics.mobilities[constants.METRIC_DESTINATIONS_TEACHER_FIELD].processed
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.mobilitiesMain.destinationsStudent')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: {
                                processed: investigation.metrics.mobilities[constants.METRIC_DESTINATIONS_STUDENT_FIELD].processed.flatMap(item => item.group),
                                tools: investigation.metrics.mobilities[constants.METRIC_DESTINATIONS_STUDENT_FIELD].tools
                            },
                            onChange: (value) => handleOnChange(value, constants.METRIC_DESTINATIONS_STUDENT_FIELD, investigation),
                            columns: {
                                originCountry: t('dependence.investigation.mobilitiesMain.originCountry'),
                                originCity: t('dependence.investigation.mobilitiesMain.originCity'),
                                destinationCountry: t('dependence.investigation.mobilitiesMain.destinationCountry'),
                                destinationCity: t('dependence.investigation.mobilitiesMain.destinationCity'),
                                value: t('dependence.investigation.mobilitiesMain.amount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ConnectionsWorMap
                            infoChart={{
                                data: investigation.metrics.mobilities[constants.METRIC_DESTINATIONS_STUDENT_FIELD].processed
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.mobilitiesMain.duration')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.mobilities[constants.METRIC_DURATION_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_DURATION_FIELD),
                            columns: {
                                group: t('dependence.investigation.mobilitiesMain.days'),
                                value: t('dependence.investigation.mobilitiesMain.amount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <ColumnGroup
                            infoChart={{
                                data: investigation.metrics.mobilities[constants.METRIC_DURATION_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.mobilitiesMain.days'),
                                    yAxis: t('dependence.investigation.mobilitiesMain.amount')
                                }
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6} >
                    <CardGraphics
                        title={t('dependence.investigation.mobilitiesMain.type')}
                        tools={{
                            usability: true,
                            rangeTime: true,
                            gender: false,
                            type: false,
                            accumulate: true,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.mobilities[constants.METRIC_TYPE_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_TYPE_FIELD, investigation),
                            columns: {
                                axis: t('dependence.investigation.year'),
                                type: t('dependence.investigation.type'),
                                value: t('dependence.investigation.mobilitiesMain.amount'),
                            },
                            formatValue: 'number',
                            sortField: 'axis'
                        }}
                    >
                        <ColumnStacked
                            infoChart={{
                                data: investigation.metrics.mobilities[constants.METRIC_TYPE_FIELD].processed,
                                colorGender: false,
                                axis: {
                                    xAxis: t('dependence.investigation.year'),
                                    yAxis: t('dependence.investigation.mobilitiesMain.amount'),
                                },
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0.5 }}>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.mobilitiesMain.topEvents')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.mobilities[constants.METRIC_EVENTS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_EVENTS_FIELD, investigation),
                            columns: {
                                type: t('dependence.investigation.mobilitiesMain.event'),
                                value: t('dependence.investigation.mobilitiesMain.amount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.mobilities[constants.METRIC_EVENTS_FIELD].processed.slice(0, 20),
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardGraphics
                        title={t('dependence.investigation.mobilitiesMain.topPrograms')}
                        tools={{
                            usability: true,
                            rangeTime: false,
                            gender: false,
                            type: false,
                            accumulate: false,
                            wordCloud: false,
                            graph: false,
                            chart: false,
                            download: true,
                            dialog: false,
                            dataTable: true
                        }}
                        infoDialog={{
                            label: '',
                            gif: '',
                            notice: ''
                        }}
                        infoChart={{
                            data: investigation.metrics.mobilities[constants.METRIC_PROGRAMS_FIELD],
                            onChange: (value) => handleOnChange(value, constants.METRIC_PROGRAMS_FIELD, investigation),
                            columns: {
                                type: t('dependence.investigation.mobilitiesMain.program'),
                                value: t('dependence.investigation.mobilitiesMain.amount'),
                            },
                            formatValue: 'number',
                            sortField: 'value'
                        }}
                    >
                        <BarGradient
                            infoChart={{
                                data: investigation.metrics.mobilities[constants.METRIC_PROGRAMS_FIELD].processed.slice(0, 20),
                                formatValue: 'number',
                                sortField: 'value'
                            }}
                        />
                    </CardGraphics>
                </Grid>
            </Grid>
        </Box>
    )
}
const mapStateToProps = (state) => ({
    investigation: state.investigation,
    settings: state.settings
});
const mapDispatchToProps = (dispatch) => ({
    setInvestigation(state) {
        dispatch({
            type: 'SET_INVESTIGATION',
            state,
        });
    },
    setSettings(state) {
        dispatch({
            type: 'SET_SETTINGS',
            state,
        });
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Mobilities);